/* BE Region
:lang(nl-e-BE) {
}
:lang(nl-e-NL) {
}
*/
#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-rd-main-header {
        .pr-rd-review-header-contents {
          .pr-rd-review-header-sorts {
            right: 19%;
            .pr-rd-sort-group {
              padding-left: 0;
              .pr-rd-sort {
                background: url(/media/dev/icons/src/arrow--down.svg) 95% 45% / 10px no-repeat;
              }
            }
          }
          .pr-rd-search-container {
            width: 21%;
            left: 19%;
            @include breakpoint($bp--medium-down) {
              width: 100%;
            }
            @include breakpoint($bp--largest-up) {
              #{$ldirection}: 0;
            }
          }
        }
      }
      .pr-review {
        .pr-rd-footer {
          .pr-helpful-yes {
            .pr-helpful-count {
              &:before {
                content: 'Nuttig(';
              }
            }
          }
          .pr-helpful-no {
            .pr-helpful-count {
              &:before {
                content: 'Niet Nuttig(';
              }
            }
          }
          .pr-rd-flag-review-container {
            line-height: 1.5;
          }
        }
      }
    }
  }
}

.product__footer {
  .pr-snippet-stars-reco-inline {
    .pr-snippet-stars-reco-stars {
      .pr-snippet-read-and-write {
        .pr-snippet-review-count {
          display: table;
          margin: 0 0 8px 0;
          &:before {
            content: 'LEES ALLE ';
          }
        }
      }
      .pr-snippet-stars-container {
        &:before {
          content: 'Algemeen score: ';
        }
      }
    }
  }
}
/* FR region */
:lang(fr-e-BE) {
  #pr-reviewdisplay {
    .p-w-r {
      .pr-review-display {
        .pr-rd-main-header {
          .pr-rd-review-header-contents {
            .pr-rd-review-header-sorts {
              right: 21%;
              .pr-rd-sort-group {
                padding-left: 0;
                .pr-rd-sort {
                  background: url(/media/dev/icons/src/arrow--down.svg) 95% 45% / 10px no-repeat;
                }
              }
            }
            .pr-rd-search-container {
              width: 25%;
              left: 14%;
            }
          }
        }
        .pr-review {
          .pr-rd-footer {
            .pr-helpful-yes {
              .pr-helpful-count {
                &:before {
                  content: 'Utile(';
                }
              }
            }
            .pr-helpful-no {
              .pr-helpful-count {
                &:before {
                  content: 'Inutile(';
                }
              }
            }
            .pr-rd-flag-review-container {
              line-height: 1.5;
            }
          }
        }
      }
    }
  }
  .product__footer {
    .pr-snippet-stars-reco-inline {
      .pr-snippet-stars-reco-stars {
        .pr-snippet-read-and-write {
          .pr-snippet-review-count {
            display: table;
            margin: 0 0 8px 0;
            &:before {
              content: 'Lire l’ensemble des ';
            }
          }
        }
        .pr-snippet-stars-container {
          &:before {
            content: 'Note générale: ';
          }
        }
      }
    }
  }
}
