.phone-container,
.title_container {
  @include swap_direction(padding, 0.8em 0);
}

.default-shipping {
  @include swap_direction(margin, 0.8em 0);
  label {
    .adpl & {
      display: inline-block;
    }
  }
}

.profile-page {
  .profile-info {
    .profile-info__fieldset {
      .field-container {
        .form_title {
          width: 60%;
          .picker-radio {
            display: inline-block;
          }
        }
      }
    }
  }
}

.field-container {
  @include breakpoint($bp--medium-up) {
    max-width: 910px;
  }
}

.field-container--grid {
  &.address-to-use,
  &.date-of-birth {
    .form-item {
      @include breakpoint($bp--medium-up) {
        @include layout($grid--3, 1em) {
          &:nth-of-type(3n + 1) {
            @include grid-span(1, 1);
            clear: both;
            .valid_marker,
            .invalid_marker {
              right: 18px;
            }
          }
          &:nth-of-type(3n + 2) {
            @include grid-span(1, 2);
          }
          &:nth-of-type(3n + 3) {
            @include grid-span(1, 3);
          }
        }
      }
    }
  }
}

.order-details-page {
  .cart-item {
    .cart-item {
      &__type {
        margin-left: 0;
      }
    }
  }
}

.account-order-history {
  .shipments-list {
    .product__add {
      .product-item__coming-soon,
      .product-item__out-of-stock {
        width: 46%;
      }
    }
  }
}

.past-purchases,
.favorites-board {
  .js-add-button {
    .product-item__coming-soon,
    .product-item__out-of-stock {
      position: relative;
      top: 30px;
    }
  }
}

.favorites-board {
  .js-add-button {
    .notify_me {
      &.js-notify-me {
        top: -10px;
      }
    }
  }
}

.past-purchases {
  .js-add-button {
    .product-item__out-of-stock {
      position: absolute;
      line-height: 75px;
    }
  }
}
