// Hiding b_functional section in privacy slider
$enable_cookie-img: false;

.adpl {
  input[type='text'],
  input[type='email'],
  input[type='tel'],
  input[type='password'],
  select,
  textarea,
  .select2-container {
    &.error {
      border-color: $color--error;
    }
  }
}

[class*='icon--envelope'],
[class*='icon--search'] {
  line-height: inherit;
}

.site-container {
  .checkout__content {
    margin-top: 2em;
  }
  .checkout__sidebar {
    margin-top: 2em;
    #offer-code-panel {
      .offer-code__submit {
        .button {
          width: 172px;
        }
      }
    }
  }
  .site-email-sms-signup__form {
    .form--newsletter__form-items {
      #form--email_sms_signup--field--MOBILE_NUMBER {
        display: none;
      }
    }
  }
}

#checkout_shipmethod {
  padding-#{$ldirection}: 0;
}

.address_form_container {
  .click-and-collect {
    .form_element {
      &.title_container {
        .label-content {
          display: none;
        }
      }
      &.phone_1_container,
      &.phone_2_container {
        padding: 8px 0 10px 0;
      }
      &.first_name_container {
        .required_mark {
          margin-top: 35px;
        }
      }
    }
    .local-collection,
    .store-collection {
      .checkout-buttons {
        color: white;
        text-align: center;
        padding: 0 20px;
        height: 60px;
        line-height: 64px;
        border-style: solid;
        border-color: white;
        border-width: 0;
        display: inline-block;
        background-color: black;
        text-transform: uppercase;
      }
    }
  }
}

.click_and_collect_map {
  z-index: 99999;
  .map {
    position: initial !important;
  }
}

#delivery-options {
  .shipform_fields {
    .ship-method-group {
      padding: 10px 0 10px 0;
    }
  }
}

.map-container {
  .search-box-cancel {
    .icon--remove {
      padding: 7px;
    }
  }
  .search-box-geolocate {
    .icon--geolocation {
      padding: 16px;
    }
  }
  .search-box-submit {
    .icon--search {
      padding: 2px;
    }
  }
}

.waitlist_header {
  font-size: 20px;
  margin: 5px 0px !important;
}

#colorbox {
  #cboxWrapper {
    #cboxContent {
      #cboxLoadedContent {
        .waitlist-iframe-wrapper {
          width: 95%;
          height: 95%;
          margin: 0px 7px 5px 7px;
        }
      }
    }
  }
}

.tiny-waitlist-overlay {
  h2.waitlist_header {
    font-size: 20px;
    margin: 5px 0px !important;
  }
  p.waitlist_message {
    margin-bottom: 0.5em;
  }
  .waitlist_thankyou_heading {
    font-size: 20px;
    margin: 80px 0 0px 0;
    text-align: center;
  }
  .waitlist_thankyou_message {
    margin-top: 10px;
    text-align: center;
  }
  .field {
    width: 100% !important;
    float: #{$ldirection};
    position: relative;
    .email_input {
      float: #{$ldirection};
      width: 100%;
      input {
        width: 79%;
        padding: 12px;
      }
    }
    .email_promo {
      float: #{$ldirection};
      width: 100%;
      padding-top: 3px;
      input[type='checkbox'] {
        -webkit-appearance: checkbox;
      }
      label {
        display: inline !important;
      }
    }
    .align-r {
      float: left;
      margin-left: 10px;
      position: absolute;
      top: 0px;
      #{$rdirection}: 10px;
      .input-btn {
        padding: 10px;
        height: auto;
        line-height: normal;
        width: 70px;
      }
    }
  }
}

.site-footer--top {
  .grid-container--6 .grid--item {
    &:nth-of-type(6n + 2) {
      width: 19.66667%;
      margin-left: 19.66667%;
    }
    &:nth-of-type(6n + 3) {
      width: 19.66667%;
      margin-left: 39.33333%;
    }
    &:nth-of-type(6n + 4) {
      width: 19.66667%;
      margin-left: 59%;
    }
    &:nth-of-type(6n + 5) {
      width: 19.66667%;
      margin-left: 78.66667%;
    }
  }
}

.confirmation-panel__content {
  border-top: 1px solid #dbdbdb;
}

#confirmation-panel {
  h2.checkout__panel-title {
    margin-top: 0;
    padding-top: 15px;
  }
}

.site-header {
  .site-header__prefix {
    @include breakpoint($width-xxlarge) {
      width: 121px;
      margin-#{$ldirection}: 12px;
    }
  }
  .site-header__menu {
    @include breakpoint($width-xxlarge) {
      width: 844px;
      margin-#{$ldirection}: 172px;
    }
  }
  .site-header__suffix {
    @include breakpoint($width-xxlarge) {
      width: 265px;
    }
  }
}

.product--coming-soon {
  &.prod_inv_status-3 {
    .product__footer {
      .product__inventory-status {
        .coming-soon {
          display: block;
        }
      }
    }
  }
}

.grid--mpp__item {
  .product--not-orderable {
    &.prod_inv_status-2,
    &.prod_inv_status-3 {
      .product__footer {
        .product__inventory-status {
          .temp-out-of-stock-short__text,
          .coming-soon_text {
            display: block;
            margin-top: 30px;
            position: absolute;
            font-size: 1rem;
          }
        }
      }
    }
  }
}

.page--spp-ultra-wide {
  .product--not-orderable {
    &.prod_inv_status-2 {
      .product__footer {
        .product__inventory-status {
          .temp-out-of-stock__text {
            display: none;
          }
          .temp-out-of-stock-short__text {
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
}

#bt_settings div {
  &.bt-content {
    &.bt-content-desktop {
      font-size: 12px;
      h3 {
        font-size: 21px;
      }
    }
  }
}

.site-container {
  #strictly_necessary,
  #cookie_funtional {
    color: #999;
  }
}

.search-results {
  &__results {
    .product--not-preorder {
      &.prod_inv_status-2 {
        .product__footer {
          .product__add-to-bag {
            display: none;
          }
        }
      }
    }
    .product--coming-soon {
      &.prod_inv_status-3 {
        .product__footer {
          .product__add-to-bag {
            display: none;
          }
        }
      }
    }
    .all-shaded-sold-out {
      &.prod_inv_status-7 {
        .product__footer {
          .product__add-to-bag {
            display: none;
          }
        }
      }
    }
  }
}

.page-product-products-customize-your-palette {
  .shade-picker__wrapper {
    top: 120px !important;
  }
}

#updated_terms_notification {
  div {
    &.bt-bottom-right {
      position: fixed;
      right: 10px;
      bottom: 210px;
      width: 21.5em;
      border: 1px solid white;
      @include swap_direction(padding, 15px);
      background-color: black;
      z-index: 1000;
      font-size: 12px;
      line-height: 16px;
      color: white;
      a {
        color: #fff;
        font-weight: bold;
        border: 0;
      }
    }
    .bt-inner-content,
    .bt_contentarea {
      @include swap_direction(margin, 10px 0 10px 0);
      h4 {
        font-size: 1.8rem;
      }
    }
  }
}

.content {
  .tout-block-landscape__image {
    width: 100% !important;
  }
  .store_landing_only {
    .tout-block-landscape__body-above {
      width: 100% !important;
    }
  }
}

.store_landing_only,
.makeup_studio_only {
  .tout-block-landscape img {
    width: auto;
  }
}

.store_landing_only {
  .tout-block-landscape img {
    margin: 0 0 10px 0;
  }
}

.info-link {
  width: 100%;
  cursor: pointer;
  margin-bottom: 10px;
  display: inline-block;
  text-decoration: underline;
  border: none;
}

.site-header {
  li.site-navigation__item,
  li.site-navigation__more {
    padding: 0 18px !important;
  }
}

.page--spp__product {
  .product--not-shoppable {
    .product__footer {
      .product__inventory-status {
        .coming-soon_text {
          position: relative;
        }
      }
    }
  }
}

body {
  div.AstuteBotLauncher {
    left: 0;
    .ToggleChat {
      display: none;
    }
    .ChatContainer {
      .ChatTitleBar {
        display: none;
      }
    }
  }
}

.product-full--enhanced {
  &.product-full {
    .product-full__spp-block {
      @include breakpoint(($bp--large-up) (orientation landscape)) {
        overflow: unset;
      }
    }
  }
}

.kit-container {
  @include swap_direction(padding, 9% 0 20px 16.5%);
  @include breakpoint($bp--medium-down) {
    @include swap_direction(margin, 0 0 0 17%);
    @include swap_direction(padding, 10px 0 0 0);
    clear: both;
  }
  .checkout__sidebar & {
    @include swap_direction(margin, 0);
    @include swap_direction(padding, 0);
    clear: both;
  }
  .cart-item {
    &__desc-info {
      @include swap_direction(margin, 0 0 20px 0);
      @include swap_direction(padding, 0 0 20px 0);
      border-bottom: 1px solid $color--black;
      &:last-child {
        @include swap_direction(margin, 0);
        @include swap_direction(padding, 0);
        border-bottom: none;
      }
    }
    .swatch {
      border-radius: 50%;
      width: 22px;
      height: 22px;
      display: inline-block;
      position: relative;
      top: 6px;
    }
    .shade {
      @include swap_direction(padding, 0 0 0 5px);
      display: inline-block;
      &-label {
        display: none;
      }
    }
    .checkout__sidebar & {
      @include swap_direction(margin, 0);
    }
  }
}

.custom-kit-formatter {
  &__landing-content & {
    &__content-wrapper {
      top: 76%;
      #{$ldirection}: 63%;
    }
    &__start-cta {
      background: $color--white;
      color: $color--black;
      font-size: 15px;
    }
  }
}

.cr21-refresh {
  .site-header-formatter {
    &__utilities-content {
      .header-search {
        &.header-search-display-input {
          .header-search {
            &__icon {
              i {
                height: 100%;
                background-position: 50%;
              }
            }
          }
        }
      }
      .site-my-mac {
        &__link-icon {
          height: 45px;
          background-position: 50%;
        }
      }
      .site-bag {
        &__link-icon {
          height: 100%;
          background-position: 50%;
        }
      }
    }
  }
}

.viewcart {
  .payments-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    margin: 10px auto 0;
    .logo {
      background-position: center;
      margin: 0 7px;
      max-width: 40px;
      width: 100%;
      height: 27px;
    }
  }
}
