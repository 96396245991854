.checkout {
  &-header {
    #top-viewcart-buttons {
      .continue-buttons {
        width: auto;
        padding-#{$rdirection}: 10%;
      }
    }
  }
  #bottom-viewcart-buttons {
    .continue-buttons {
      width: auto;
    }
  }
  .offer-code__input {
    @include swap_direction(padding, 1em 0);
  }
  .sample-select-container {
    .checkbox {
      float: $rdirection;
      .picker-handle {
        display: none;
      }
    }
  }
  span {
    &.title {
      float: $ldirection;
    }
  }
  &__content {
    &.#{$ldirection} {
      margin-bottom: 1em;
      .checkout-buttons-container {
        @include swap_direction(margin, 1em 0);
        float: $rdirection;
      }
    }
  }
  &.viewcart {
    .cross_sell_container {
      margin-top: 10px;
    }
  }
  .samples {
    .deactivate {
      .btn {
        &:hover {
          color: $color--black;
          text-decoration: none;
        }
      }
    }
  }
  &.shipping {
    .shipping-address-info {
      .address-container {
        float: $ldirection;
        width: 300px;
        word-wrap: break-word;
      }
      .address_controls {
        float: $ldirection;
        width: 50%;
        .text_link {
          display: block;
          border-bottom: none;
          text-decoration: underline;
        }
        #choose-address {
          margin-top: 15px;
        }
      }
    }
  }
  &.review,
  &.payment {
    .trans_detail_item {
      float: $ldirection;
      width: 300px;
      margin-right: 10px;
      word-wrap: break-word;
      margin-bottom: 20px;
      h4 {
        font-size: 2rem;
        float: $ldirection;
      }
      .change_link {
        float: $rdirection;
        margin-#{$rdirection}: 20px;
        margin-top: 7px;
      }
    }
  }
  .confirmation-panel {
    .checkout-header__title {
      width: 100%;
      padding-#{$ldirection}: 0;
    }
  }
  .terms-text {
    @include swap_direction(margin, 0.8em 0);
  }
  .site-header {
    .site-header__tools {
      .site-my-mac {
        &__link {
          display: block;
        }
      }
    }
  }
}

#recommended-products-panel {
  .recommended-product-items {
    .recommended-item {
      .formatted_price {
        position: relative;
        bottom: 0;
      }
    }
  }
}

.address_form_container {
  .picker {
    width: 100%;
  }
}

#bill_to_shipping_container {
  .picker {
    .picker-handle {
      @include swap_direction(margin, 0 15px 0 2px);
    }
  }
}

.create-account {
  #completed_checkout_registration {
    .pc_email_container {
      label {
        display: block;
      }
    }
  }
}

.past-purchases-page__content {
  .past-purchases {
    .notify-status {
      .js-notify-me {
        margin-top: -2px;
      }
      .product-item__out-of-stock {
        top: 65px;
      }
    }
  }
}

.account-order-history {
  .products {
    .product__add {
      .product-item__out-of-stock {
        float: right;
      }
    }
  }
}

.giftwrap-info {
  .gift_options_container {
    .gift-card-fields {
      .form_element {
        padding: 10px 0 10px 0;
      }
    }
  }
}

.map-container {
  .location-info-windows,
  .map-info-window {
    .location {
      .button {
        .select-access-point {
          width: 11em;
          padding: 0;
        }
      }
    }
  }
  .map-info-window {
    .location {
      .button {
        .get-directions {
          width: 10em;
          padding: 0;
        }
      }
    }
  }
  .directions {
    .select-access-point_dir {
      position: relative;
      top: 50px;
      margin-#{$ldirection}: 10px;
      line-height: 40px;
      height: 40px;
    }
  }
}

.review {
  #review-address-info {
    .trans_detail_item {
      &.delivery_method_container {
        clear: none !important;
      }
      &.gift_option_container {
        clear: both;
      }
    }
  }
}

#confirm {
  .responsive-container {
    &.pg_wrapper,
    &.confirmation-panel {
      max-width: 100%;
    }
  }
  a.info-link[message-in='confirm-sign-notice'] {
    float: none;
    border-bottom: 0px;
    text-decoration: underline $color--gray--lighter;
  }
}

#viewcart {
  .continue-buttons {
    &.float-right {
      padding: 0;
    }
  }
}

#samples-panel {
  .samples-panel__content {
    top: -43px;
    .sample-products {
      .choose-sample {
        .sample-select-button {
          @include swap_direction(padding, 0);
        }
      }
    }
    div {
      &.offer {
        div {
          &:first-child {
            .remaining {
              position: relative;
              top: -124px;
              padding-top: 11px;
            }
          }
        }
      }
    }
  }
  .checkout-header {
    @include swap_direction(padding, 0.5em 0 5em 0);
    h2 {
      &.samples-panel__title {
        @include swap_direction(padding, 0);
        width: auto;
      }
    }
  }
}

#colorbox {
  &.max_purchase {
    #cboxWrapper {
      height: 350px !important;
    }
    div {
      &.narrowed_body_copy {
        p,
        ul,
        li,
        table,
        strong {
          &:not(.utilisation) {
            display: none;
          }
        }
        h5 {
          display: none;
        }
        .utilisation {
          padding-top: 25px;
        }
      }
    }
  }
}
